import { select, t } from '@lingui/macro';
import classnames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { pl } from 'date-fns/locale';
import React, { Component } from 'react';

import {
  TActivityAction,
  TActivityAction$Review,
  TActivityAction$Request,
  TActivityAction$Conversation,
} from '../../common/types';
import {
  feedActionTypes,
  feedActionTextParser,
  getTemplateOptionsForCategory,
} from 'Components/ActivityFeed/common/helpers';
import Image from 'common/Image/Image';
import RatingStars from 'common/RatingStars/RatingStars';
import config from 'config/config';

import './ActivityFeedAction.scss';

export type ActivityFeedAction$Props = {
  action: TActivityAction;
  hideAvatars?: boolean;
  hideCity: boolean;
};

class ActivityFeedAction extends Component<ActivityFeedAction$Props> {
  static MARGIN_BOTTOM_VALUE = 10; // Value in pixels

  shouldRenderAvatars = true;
  dateString = '';

  shouldComponentUpdate(nextProps: ActivityFeedAction$Props) {
    const newDateString = this.getDateString(nextProps.action.createdAt);
    return this.props.hideAvatars !== nextProps.hideAvatars || this.dateString !== newDateString;
  }

  getDateString = (createdAt: string): string => {
    const createdAtDate = new Date(createdAt);
    const fromNow = formatDistanceToNow(createdAtDate, {
      addSuffix: true,
      locale: pl,
    });
    const diffInSeconds = Math.abs(Date.now() - createdAtDate.getTime()) / 1000;

    return diffInSeconds >= 30 && diffInSeconds < 60 // If the item is in between 30 and 60 seconds old
      ? t`30 sekund temu` // show `30 seconds ago`
      : fromNow; // otherwise show `few seconds ago` or `N minutes ago`
  };
  getAvatarClassName = (modifier = '') =>
    classnames({
      activityFeedAction__avatar: true,
      [modifier]: Boolean(modifier),
    });
  renderAvatar = (url: string, className?: string): React.ReactElement<React.ComponentProps<any>, any> => (
    <Image
      src={url}
      size="xs"
      webp
      multiPixelRatios
      backgroundImageMode
      defaultJPEG
      className={this.getAvatarClassName(className)}
      alt="avatar"
      key={url}
      lazy
    />
  );
  renderAvatars = (urls: string[], className?: string): React.ReactElement<React.ComponentProps<any>, any>[] =>
    urls.map((u) => this.renderAvatar(u, className));
  renderConversationActionContent = (action: TActivityAction$Conversation) => {
    const { text, user, category, providerAvatarUrls } = action;
    const contentString = feedActionTextParser(text, {
      user_name: `<b>${user.name}</b>`,
      category: getTemplateOptionsForCategory(category),
    });
    const conversationAvatars =
      providerAvatarUrls.length > config.MAX_REQUEST_SLOTS
        ? providerAvatarUrls.slice(0, config.MAX_REQUEST_SLOTS - 1)
        : providerAvatarUrls;
    return (
      <>
        <p
          className="activityFeedAction__content"
          dangerouslySetInnerHTML={{
            __html: contentString,
          }}
        />
        {this.renderAvatars(conversationAvatars, 'activityFeedAction__avatar_conversation')}
        {conversationAvatars.length !== providerAvatarUrls.length && (
          <span className="activityFeedAction__truncatedAvatarsCounter">
            +{providerAvatarUrls.length - conversationAvatars.length}
          </span>
        )}
      </>
    );
  };
  renderRequestActionContent = ({ text, user, category }: TActivityAction$Request) => {
    const contentString = feedActionTextParser(text, {
      user_name: `<b>${user.name}</b>`,
      category: getTemplateOptionsForCategory(category),
    });
    return (
      <p
        className="activityFeedAction__content"
        dangerouslySetInnerHTML={{
          __html: contentString,
        }}
      />
    );
  };
  renderReviewActionContent = (action: TActivityAction$Review) => {
    const { text, user, category, providerName, reviewRating } = action;
    const contentString = feedActionTextParser(text, {
      user_name: `<b>${user.name}</b>`,
      provider_name: `<b>${providerName}</b>`,
      category: getTemplateOptionsForCategory(category),
    });

    const getRatingLabel = (value: number) =>
      select(`_${value}`, {
        _1: 'Fatalny',
        _2: 'Zły',
        _3: 'OK',
        _4: 'Dobry',
        _5: 'Doskonały!',
      });

    return (
      <>
        <p
          className="activityFeedAction__content"
          dangerouslySetInnerHTML={{
            __html: contentString,
          }}
        />
        {reviewRating && (
          <div className="activityFeedAction__ratingContainer">
            <RatingStars
              rating={reviewRating}
              className="activityFeedAction__ratingStars"
              starClassName="activityFeedAction__star"
            />
            <span className="activityFeedAction__ratingTextRepresentation">{getRatingLabel(reviewRating)}</span>
          </div>
        )}
      </>
    );
  };

  renderContent(action: TActivityAction) {
    switch (action.type) {
      case feedActionTypes.request:
        /* @ts-expect-error type mismatch */
        return this.renderRequestActionContent(action);

      case feedActionTypes.conversation:
        /* @ts-expect-error type mismatch */
        return this.renderConversationActionContent(action);

      case feedActionTypes.review:
        /* @ts-expect-error type mismatch */
        return this.renderReviewActionContent(action);

      default:
        return null;
    }
  }

  renderAvatarSection(action: TActivityAction) {
    if (this.props.hideAvatars) {
      return null;
    }

    switch (action.type) {
      case feedActionTypes.review:
        return (
          <>
            {this.renderAvatar(action.user.avatarUrl, 'activityFeedAction__avatar_reviewUser')}
            {/* @ts-expect-error type mismatch */}
            {this.renderAvatar(action.providerAvatarUrl, 'activityFeedAction__avatar_reviewProvider')}
          </>
        );

      case feedActionTypes.request:
      case feedActionTypes.conversation:
      default:
        return this.renderAvatar(action.user.avatarUrl);
    }
  }

  renderDateCityInfo() {
    const {
      hideCity,
      action: { createdAt, cityName },
    } = this.props;
    this.dateString = this.getDateString(createdAt);
    return hideCity ? this.dateString : `${this.dateString}, ${cityName}`;
  }

  render() {
    const { action } = this.props;
    return (
      <article className="activityFeedAction">
        {this.renderAvatarSection(action)}
        <div className="activityFeedAction__contentWrapper">
          {this.renderContent(action)}
          <p className="activityFeedAction__dateCityInfo">{this.renderDateCityInfo()}</p>
        </div>
      </article>
    );
  }
}

export default ActivityFeedAction;
