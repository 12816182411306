/* @ts-expect-error no type */
import { ContextRouter } from 'react-router-dom';

import { TActivityAction } from 'Components/ActivityFeed/common/types';
import config from 'config/config';

// TYPES
export type ActivityFeedContainer$VisualProps = {
  mobileShortVersion?: boolean;
  desktopShortVersion?: boolean;
  hideAvatarsDesktop?: boolean;
  hideAvatarsMobile?: boolean;
  withGreyBackground?: boolean;
  withDynamicHeight?: boolean;
  wihoutCategoryLinks?: boolean;
  itemsContainerClassName?: string;
};
export type ActivityFeedContainer$Props = ContextRouter &
  ActivityFeedContainer$VisualProps & {
    activityFeed: TActivityAction[];
    loading: boolean;
  };
export type ActivityFeedContainer$State = {
  isIntersecting: boolean;
};
export const isMobileViewport = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }

  return window.innerWidth <= config.MOBILE_BREAK_POINT;
};
