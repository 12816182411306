import routes from 'utils/routeTranslator';
import { ActivityFeedContainer$VisualProps } from '../Components/ActivityFeedContainer/ActivityFeedContainer.helpers';

import { ActivityFeed$Props, TActivityFeed, IFeedActionTextParser, IGetTemplateOptionsForCategory } from './types';

export const feedActionTypes = {
  request: 'request',
  conversation: 'conversation',
  review: 'review',
};
export const feedTemplateKeyRegExp = /:([\w+|_]+)/gi;
export const feedActionTextParser: IFeedActionTextParser = (s, options) =>
  /* @ts-expect-error type mismatch */
  s.replace(feedTemplateKeyRegExp, (match, key) => {
    switch (typeof options[key]) {
      case 'string':
        return options[key];

      case 'object': {
        /* @ts-expect-error type mismatch */
        if (options[key].templateString && options[key].options) {
          /* @ts-expect-error type mismatch */
          return feedActionTextParser(options[key].templateString, options[key].options);
        }

        return match;
      }

      case 'undefined':
      default:
        return match;
    }
  });
export const getTemplateOptionsForCategory: IGetTemplateOptionsForCategory = ({ slug, name }) =>
  /* @ts-expect-error type mismatch */
  slug
    ? {
        templateString: '<a href=":link">:name</a>',
        options: {
          link: routes.get('front::category', {
            slug,
          }),
          name,
        },
      }
    : {
        templateString: '<span class="pseudo-link">:name</span>',
        options: {
          name,
        },
      };
export const passVisualProps = ({
  hideAvatarsDesktop,
  hideAvatarsMobile,
  withGreyBackground,
  mobileShortVersion,
  desktopShortVersion,
  withDynamicHeight,
  itemsContainerClassName,
}: ActivityFeed$Props): ActivityFeedContainer$VisualProps => ({
  hideAvatarsDesktop,
  hideAvatarsMobile,
  withGreyBackground,
  mobileShortVersion,
  desktopShortVersion,
  withDynamicHeight,
  itemsContainerClassName,
});
export const dropOlderThan = (feed: TActivityFeed, dropDate: number): TActivityFeed => {
  const firstNewerIndex = feed
    .slice()
    .reverse()
    .findIndex((a) => Date.parse(a.createdAt) > dropDate);
  return firstNewerIndex !== -1 ? feed.slice(0, firstNewerIndex + 1) : feed;
};

/**
 * NOTE: Pay attention that this function is used inside worker and has it's own local scope
 */
export function preciseTimeoutWorkerFunction() {
  /* eslint-disable no-restricted-globals */
  const fireTimeout = () =>
    self.postMessage({
      type: 'tick',
    });

  const fireTimeoutIn = (duration: number) => {
    setTimeout(fireTimeout, duration);
  };

  self.onmessage = ({ data }: MessageEvent) => {
    fireTimeoutIn(data && data.timeout ? Number(data.timeout) : 100);
  };
  /* eslint-enable no-restricted-globals */
}
/* eslint import/prefer-default-export:0, max-len:0 */
