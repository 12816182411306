import { t } from '@lingui/macro';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import classnames from 'classnames';
import throttle from 'lodash.throttle';
import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { TActivityAction, TActivityFeed } from '../../common/types';
import { isWindow } from 'common/helpers/helpers';

import ActivityFeedAction from '../ActivityFeedAction/ActivityFeedAction';
import {
  ActivityFeedContainer$Props,
  ActivityFeedContainer$State,
  isMobileViewport,
} from './ActivityFeedContainer.helpers';
import './ActivityFeedContainer.scss';

const ANIMATION_DURATION = 300;
const DESKTOP_FEED_HEIGHT = 585;
const MOBILE_FEED_HEIGHT = 430;
const FEED_HEIGHT_FOR_TWO = 290;

class ActivityFeedContainer extends Component<ActivityFeedContainer$Props, ActivityFeedContainer$State> {
  scrollableElement: HTMLDivElement | null | undefined = null;
  scrollUpButtonContainer: HTMLDivElement | null | undefined = null;
  scrollDownButtonContainer: HTMLDivElement | null | undefined = null;
  itemsContainer: {
    current: HTMLDivElement | null | undefined;
  } = createRef();
  scrollPosition = 0;
  scrolledToIndex = 0;
  scrollSteps: number[] = [];
  appearAnimationStartedAt: number | null = null;
  /* @ts-expect-error type mismatch */
  animationTimeout: TimeoutID;
  isMobileViewport: boolean = isMobileViewport();
  shouldHideCity = false;
  mountedWithData = false;
  state = {
    isIntersecting: false,
  };

  /* @ts-expect-error type mismatch */
  constructor(props) {
    super(props);
    this.mountedWithData = props.activityFeed.length > 0;
  }

  registerScrollableRef = (el: HTMLDivElement | null | undefined) => {
    this.scrollableElement = el;
  };
  registerScrollUpButtonRef = (el: HTMLDivElement | null | undefined) => {
    this.scrollUpButtonContainer = el;
  };
  registerScrollDownButtonRef = (el: HTMLDivElement | null | undefined) => {
    this.scrollDownButtonContainer = el;
  };
  handleNewActionAppearing = () => {
    this.appearAnimationStartedAt = Date.now();
    // Hack for those cases when onFinishAll callback is not fired
    this.animationTimeout = setTimeout(this.handleNewActionAppeared, ANIMATION_DURATION * 1.2);
  };
  handleNewActionAppeared = () => {
    clearTimeout(this.animationTimeout);
    this.appearAnimationStartedAt = null;
  };

  shouldComponentUpdate() {
    // It should show new items only if the user is at the top of the list
    return this.scrolledToIndex === 0;
  }

  UNSAFE_componentWillMount() {
    if (!isWindow) {
      return;
    }

    this.updateViewportWidth();
  }

  componentDidMount() {
    this.shouldHideCity = Boolean(this.props.match.params.city);

    if (!isWindow) {
      return;
    }

    window.addEventListener('resize', this.updateViewportWidth, {
      passive: true,
    });
  }

  componentWillUnmount() {
    if (!isWindow) {
      return;
    }

    window.removeEventListener('resize', this.updateViewportWidth);
  }

  /**
   * If it's mobile viewport - render only 15 most recent activity actions
   * on desktop -- render all
   * this is for performance purposes
   */
  getVisibleActivityFeed = (activityFeed: TActivityFeed): TActivityFeed =>
    this.isMobileViewport ? activityFeed.slice(0, 15) : activityFeed.slice(0, 25);
  updateViewportWidth = throttle(() => {
    this.isMobileViewport = isMobileViewport();
  }, 300);
  waitForAppearAnimation =
    (cb: (...args: any) => void) =>
    (
      ...args: any // Weird construction below because flow doesn't understand that this.appearAnimationStartedAt is a number
    ) =>
      this.appearAnimationStartedAt
        ? setTimeout(cb, Date.now() - (this.appearAnimationStartedAt || 0), ...args)
        : cb(...args);
  setScrollButtonVisibility = (direction: 'up' | 'down', isVisible: boolean) => {
    const buttonContainerElement =
      direction === 'up' ? this.scrollUpButtonContainer : direction === 'down' ? this.scrollDownButtonContainer : null;

    if (!buttonContainerElement) {
      return;
    }

    buttonContainerElement.style.display = isVisible ? 'block' : 'none';
  };
  scrollFeedTo = (position: number) => {
    if (!this.scrollableElement) {
      return;
    }

    // NOTE: It should inverse the target position value to scroll in expected direction
    this.scrollableElement.style.transform = `translateY(${-position}px)`;
  };
  getDynamicFeedHeight = (): number =>
    this.itemsContainer.current
      ? this.itemsContainer.current.clientHeight // If for some reason there is not ref for items container -> fallback to static height
      : this.getStaticFeedHeight();
  getStaticFeedHeight = (): number => {
    const { mobileShortVersion, desktopShortVersion } = this.props;
    return this.isMobileViewport
      ? mobileShortVersion
        ? FEED_HEIGHT_FOR_TWO
        : MOBILE_FEED_HEIGHT
      : desktopShortVersion
      ? FEED_HEIGHT_FOR_TWO
      : DESKTOP_FEED_HEIGHT;
  };
  checkScrollButtonVisibility = (feedHeight: number, nextScrollPosition: number) => {
    const { withDynamicHeight } = this.props;
    const maximumFeedHeight: number = withDynamicHeight ? this.getDynamicFeedHeight() : this.getStaticFeedHeight();

    if (feedHeight - nextScrollPosition < maximumFeedHeight) {
      this.setScrollButtonVisibility('down', false);
    }
  };
  scrollUp = this.waitForAppearAnimation(() => {
    if (this.scrollSteps.length === 0) {
      return;
    }

    // Means that we already at the top
    const lastScrollStep = this.scrollSteps[this.scrollSteps.length - 1];
    const scrollStepsLeft = this.scrollSteps.slice(0, -1);
    const nextScrolledToIndex = Math.max(this.scrolledToIndex - 1, 0);
    this.scrollSteps = scrollStepsLeft;
    this.scrolledToIndex = nextScrolledToIndex;
    this.scrollPosition = this.scrollPosition - lastScrollStep;
    this.scrollFeedTo(this.scrollPosition);
    this.setScrollButtonVisibility('down', true);

    if (nextScrolledToIndex === 0) {
      this.setScrollButtonVisibility('up', false);
      this.forceUpdate();
    }
  });
  scrollDown = this.waitForAppearAnimation(() => {
    const nextScrolledToIndex = this.scrolledToIndex + 1;

    if (!this.scrollableElement) {
      return;
    }

    const currentFirstVisible = this.scrollableElement.querySelector(
      `.activityFeedAction:nth-child(${nextScrolledToIndex})`
    );
    // $FlowFixMe doesn't understand that scrollableElement is present here
    const { height: feedHeight } = this.scrollableElement.getBoundingClientRect();

    if (!currentFirstVisible) {
      return;
    }

    const { height: currentVisibleActionHeight } = currentFirstVisible.getBoundingClientRect();
    const scrollStep = currentVisibleActionHeight + ActivityFeedAction.MARGIN_BOTTOM_VALUE;
    const nextScrollPosition = this.scrollPosition + scrollStep;
    this.scrollSteps = [...this.scrollSteps, scrollStep];
    this.scrolledToIndex = nextScrolledToIndex;
    this.scrollPosition = nextScrollPosition;
    this.scrollFeedTo(this.scrollPosition);
    this.setScrollButtonVisibility('up', true);
    this.checkScrollButtonVisibility(feedHeight, nextScrollPosition);
  });
  getControlButtonClassName = (type: 'up' | 'down') =>
    classnames({
      activityFeedContainer__controlButtonContainer: true,
      activityFeedContainer__controlButtonContainer_withGreyBackground:
        type === 'down' && this.props.withGreyBackground,
      [`activityFeedContainer__controlButtonContainer_${type}`]: Boolean(type),
    });
  renderControlButton = (
    type: 'up' | 'down',
    textLabel: string,
    clickHandler: () => any,
    refRegistrator: (el: HTMLDivElement | null | undefined) => void
  ) => (
    <div className={this.getControlButtonClassName(type)} ref={refRegistrator}>
      <button
        type="button"
        onClick={clickHandler}
        className={`activityFeedContainer__controlButton activityFeedContainer__controlButton_${type}`}
        title={textLabel}
      >
        <span className="activityFeedContainer__controlButtonIcon" aria-hidden />
        <span className="activityFeedContainer__controlButtonLabel">{textLabel}</span>
      </button>
    </div>
  );
  renderActivityAction = (action: TActivityAction): React.ReactElement<React.ComponentProps<any>, any> => {
    const { wihoutCategoryLinks } = this.props;
    return (
      <CSSTransition key={action.createdAt} timeout={300} classNames="activityFeedContainer__transitionItem">
        <ActivityFeedAction
          action={
            !wihoutCategoryLinks
              ? action
              : {
                  ...action,
                  category: {
                    name: action.category.name,
                  },
                }
          }
          hideCity={this.shouldHideCity}
        />
      </CSSTransition>
    );
  };
  /* @ts-expect-error type mismatch */
  handleIntersectionChange = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      this.setState(
        {
          isIntersecting,
        },
        unobserve
      );
    }
  };

  renderFeed() {
    if (!this.state.isIntersecting) {
      return null;
    }

    const { activityFeed, mobileShortVersion, desktopShortVersion, itemsContainerClassName } = this.props;
    const containerCN = classnames('activityFeedContainer');
    const itemsContainerCN = classnames(
      'activityFeedContainer__itemsContainer',
      {
        'activityFeedContainer__itemsContainer_mobileShortVersion': mobileShortVersion,
        'activityFeedContainer__itemsContainer_desktopShortVersion': desktopShortVersion,
      },
      itemsContainerClassName
    );
    const visibleActivityFeed = this.getVisibleActivityFeed(activityFeed);
    return (
      <div className={containerCN}>
        {this.renderControlButton('up', t`Wstecz`, this.scrollUp, this.registerScrollUpButtonRef)}

        {/* @ts-expect-error type mismatch */}
        <div className={itemsContainerCN} ref={this.itemsContainer}>
          <div className="activityFeedContainer__scrollableContainer" ref={this.registerScrollableRef}>
            <TransitionGroup className="activityFeedContainer__transitionGroup">
              {visibleActivityFeed.map(this.renderActivityAction)}
            </TransitionGroup>
          </div>
        </div>

        {this.renderControlButton('down', t`Dalej`, this.scrollDown, this.registerScrollDownButtonRef)}
      </div>
    );
  }

  render() {
    const { loading } = this.props;

    if (loading) {
      return null;
    }

    return (
      <IntersectionObserver onChange={this.handleIntersectionChange}>
        <div className="activityFeedContainer__intersectingWrapper">{this.renderFeed()}</div>
      </IntersectionObserver>
    );
  }
}

export default withRouter(ActivityFeedContainer);
/* eslint max-len:0, no-nested-ternary:0 */
