import { subMinutes } from 'date-fns';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import ActivityFeedContainer from './Components/ActivityFeedContainer/ActivityFeedContainer';
import { dropOlderThan, passVisualProps, preciseTimeoutWorkerFunction } from './common/helpers';
import { ActivityFeed$Props, ActivityFeed$State, TActivityFeed } from './common/types';
import { withNoSSR } from 'Components/NoSSR';
import { compose } from 'common/helpers/compose';
import convertFunctionToWorker from 'common/helpers/functionToWorker';
import { isWindow } from 'common/helpers/helpers';
import api from 'utils/api';
import { captureException } from 'utils/errorLogger/sentry';

const COUNT_TO_START_LOAD_MORE = 3;
const INITIAL_ACTION_LOAD_LIMIT = 10;
const REGULAR_ACTION_LOAD_LIMIT = 10;

class ActivityFeed extends Component<ActivityFeed$Props, ActivityFeed$State> {
  state = {
    feed: [],
  };
  /* @ts-expect-error type mismatch */
  updateFeedTimeout: ReturnType<typeof setTimeout>;
  recentActionIndex = 0;
  newestLoadedDate: string | null | undefined;
  preciseTimeoutWorker: Worker | null | undefined = null;

  async componentDidMount() {
    this.initTimeoutWorker();
    let newFeed = [];

    try {
      const { fromDate, limit, initial } = this.getFeedLoadParams();
      newFeed = await this.fetchFeed(fromDate, limit, initial);
    } catch (err) {
      /* @ts-expect-error type mismatch */
      captureException(err);
      return;
    }

    this.arrangeFeedActions(newFeed, false);
    this.setFeed(newFeed);
  }

  componentWillUnmount() {
    if (!this.preciseTimeoutWorker) {
      return;
    }

    this.preciseTimeoutWorker.terminate();
  }

  initTimeoutWorker = () => {
    this.preciseTimeoutWorker = convertFunctionToWorker(preciseTimeoutWorkerFunction);
    this.preciseTimeoutWorker.onmessage = this.handleTimeoutWorkerMessage;
  };
  setWorkerTimeout = (timeout: number) => {
    if (!this.preciseTimeoutWorker) {
      return;
    }

    this.preciseTimeoutWorker.postMessage({
      timeout,
    });
  };
  handleTimeoutWorkerMessage = (e: MessageEvent) => {
    if (e.data && e.data.type !== 'tick') {
      return;
    }

    this.arrangeFeedActions();
  };
  getFeedLoadParams = (
    offset = 1
  ): {
    fromDate: string;
    limit: number;
    initial: boolean;
  } => {
    const newestLoadedDateDiff = !this.newestLoadedDate
      ? 0
      : Date.now() - new Date(this.newestLoadedDate).getTime() / 60000;

    if (!this.newestLoadedDate || newestLoadedDateDiff >= 5) {
      return {
        fromDate: subMinutes(new Date(), 2).toISOString(),
        limit: INITIAL_ACTION_LOAD_LIMIT,
        initial: true,
      };
    }

    return {
      fromDate: this.newestLoadedDate ? this.newestLoadedDate : subMinutes(new Date(), offset).toISOString(),
      limit: REGULAR_ACTION_LOAD_LIMIT,
      initial: false,
    };
  };
  setFeed = (feed: TActivityFeed) => this.setState({ feed });

  setNewestLoadedDate = (feed: TActivityFeed) => {
    this.newestLoadedDate = feed.length ? feed[0].createdAt : this.newestLoadedDate;
  };
  fetchFeed = async (fromDate: string, limit: number, initial: boolean): Promise<TActivityFeed> => {
    const response = await api.getActivityFeed(fromDate, limit, initial, this.props.l2Slug);

    if (!response || !response.ok) {
      throw new Error('Cannot load feed');
    }

    const feed: TActivityFeed = await response.json();
    this.setNewestLoadedDate(feed);
    return feed;
  };
  fetchMoreActions = async (): Promise<TActivityFeed> => {
    await Promise.resolve(); // Wait for the call-stack to be clear

    const { fromDate, limit, initial } = this.getFeedLoadParams();
    let newFeed = [];

    try {
      newFeed = await this.fetchFeed(fromDate, limit, initial);
    } catch (e) {
      /* @ts-expect-error type mismatch */
      captureException(e);
      return this.state.feed;
    }

    return [
      ...newFeed.slice(0, newFeed.length - 1),
      ...dropOlderThan(this.state.feed, Date.parse(subMinutes(new Date(), 2).toISOString())),
    ];
  };
  findRecentActionIndex = (actions: TActivityFeed = this.state.feed): number => {
    const now = Date.now();
    return actions.findIndex(({ createdAt }) => Date.parse(createdAt) < now);
  };
  arrangeFeedActions = async (actions: TActivityFeed = this.state.feed, shouldForceUpdate = true): Promise<void> => {
    let feed = actions;
    let loadedNewFeed = false;
    this.recentActionIndex = this.findRecentActionIndex(feed);

    if (this.recentActionIndex < COUNT_TO_START_LOAD_MORE) {
      feed = await this.fetchMoreActions();
      this.recentActionIndex = this.findRecentActionIndex(feed);
      loadedNewFeed = true;
    }

    this.setUpdateTimeout(feed);

    if (loadedNewFeed) {
      this.setFeed(feed);
    }

    if (shouldForceUpdate && !loadedNewFeed) {
      this.forceUpdate();
    }
  };
  setUpdateTimeout = async (actions: TActivityFeed): Promise<void> => {
    // Should work only on client side
    if (!isWindow) {
      return;
    }

    const nextActionIndex = this.recentActionIndex - 1;

    if (nextActionIndex > 0) {
      const { createdAt } = actions[nextActionIndex];
      const timeoutDuration = Date.parse(createdAt) - Date.now();
      this.setWorkerTimeout(timeoutDuration);
    }
  };

  render() {
    return (
      <ActivityFeedContainer
        loading={this.state.feed.length === 0}
        activityFeed={this.state.feed.slice(this.recentActionIndex)}
        wihoutCategoryLinks={this.props.wihoutCategoryLinks}
        {...passVisualProps(this.props)}
      />
    );
  }
}

/* @ts-expect-error type mismatch */
export default compose(withNoSSR, withRouter)(ActivityFeed);
