import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';

import './RatingStars.scss';

export type RatingStarsProps = {
  rating: number;
  className?: string;
  starClassName?: string;
  size?: 'small' | 'normal' | 'big' | 'x-big';
  outlined?: boolean;
};

const RatingStars: FC<RatingStarsProps> = (props) => {
  const { rating, className, starClassName, size, outlined } = props;

  const starsCN = classnames('ratingStars', className);

  const renderStar = (order: number, isFilled: boolean): ReactNode => {
    const emptyModifier = outlined ? 'ratingStars__star_grayOutlined' : 'ratingStars__star_gray';

    const singleStarCN = classnames(
      {
        'ratingStars__star': true,
        [`ratingStars__star_${size || ''}`]: Boolean(size),
        [emptyModifier]: !isFilled,
        'ratingStars__star_gold': isFilled,
      },
      starClassName
    );

    return <span className={singleStarCN} key={`${order}_star`} aria-hidden></span>;
  };

  return <span className={starsCN}>{[1, 2, 3, 4, 5].map((num) => renderStar(num, num <= Math.round(rating)))}</span>;
};

export default RatingStars;
