const workerMap = new Map();

const functionBodyRegex = /^function [^{]+\{((?:.|\n|\s)*)\}$/i;

function dropCacheFor(func: Function) {
  workerMap.delete(func);
}

/**
 * Takes function's body and creates worker from it
 * NOTE: you should pass function defined using `function` declaration, not arrow function
 * and it should not take any arguments;
 * e.g.: `function myWorker() { [...] }`
 */
export default function convertFunctionToWorker(func: Function): Worker {
  // if for given function is already created -> return cached worker
  const cachedWorker = workerMap.get(func);
  if (cachedWorker instanceof Worker) {
    return cachedWorker;
  }

  const [, workerBody] = functionBodyRegex.exec(func.toString()) || [];

  if (!workerBody) {
    throw new Error(`Cannot convert to Worker function "${func.toString()}"`);
  }

  let blob;
  try {
    blob = new Blob([workerBody], { type: 'application/javascript' });
  } catch (e) {
    // Backwards-compatibility
    // @ts-ignore
    const BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;
    blob = new BlobBuilder();
    blob.append(workerBody);
    blob = blob.getBlob();
  }
  const worker = new Worker(URL.createObjectURL(blob));

  workerMap.set(func, worker);

  return worker;
}

convertFunctionToWorker.dropCacheFor = dropCacheFor;
